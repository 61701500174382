// import { createRef, useEffect, useState } from 'react'
import { useEffect } from 'react'

// import ReCAPTCHA from 'react-google-recaptcha'
// import { RECAPTCHA_KEY } from 'gatsby-env-variables'
// import { useLocation } from '@reach/router'
// import { Form, Formik } from 'formik'
// import Layout from 'layouts/Proposal'
// import phonesImage from 'assets/images/loans-personal-data-cel-card.png'
// import Input from 'components/Forms/Fields/Input'
// import Navigation from 'components/Forms/Navigation'
// import Loader from 'components/Loader'
// import { useLead } from 'hooks/useLead'
// import getPath from 'utils/envs/getPath'
import isClient from 'utils/envs/isClient'

// import ga from 'utils/thirdParty/ga'

// import { useCleanOlderProposals, useClearFirstName, useOnSubmit } from './hooks'
// import './personal-data.styl'

// const { home } = ROUTES

// const recaptchaRef = createRef()

const PersonalData = () => {
  // const { leadData, addLeadData } = useLead()
  // const [isLoading, setIsLoading] = useState(true)
  // const lead = leadData ?? {}
  // const { state: locationState = {} } = useLocation()

  // useClearFirstName()

  // const [hasRecaptcha, setHasRecaptcha] = useState(true)

  // const otherStepsData = {
  //   amount: lead?.proposal?.amount ?? '',
  //   installments: lead?.proposal?.installments ?? '',
  //   firstDueDate: lead?.proposal?.firstDueDate ?? ''
  // }

  // const onSubmit = useOnSubmit(setIsLoading, otherStepsData)

  useEffect(() => {
    // setIsLoading(false)
    // addLeadData({ trackEvent: {} })
    isClient() &&
      window.location.assign(
        'https://emprestimo.itau.com.br/?utm_source=redirect-iti'
      )

    // }, [addLeadData])
  }, [])
  // useCleanOlderProposals()

  // const dispatchRecaptcha = (event) => {
  //   event.preventDefault()
  //   hasRecaptcha && recaptchaRef.current.execute()
  //   ga.trackClick('Continuar')
  // }

  // useEffect(() => {
  //   if (BUILD_ENV === 'local') {
  //     setHasRecaptcha(false)
  //   }
  // }, [])

  return (
    <></>
    // <Layout className="personal-data">
    //   <div className="container">
    //     <Formik
    //       initialValues={{
    //         name: lead?.name ?? '',
    //         cpf: lead?.cpf || locationState?.cpf || '',
    //         email: lead?.email ?? '',
    //         phone: lead?.phone ?? ''
    //       }}
    //       validateOnMount
    //       onSubmit={onSubmit}
    //     >
    //       {({ isValid, setFieldValue, submitForm }) => {
    //         const isDisabled = !isValid

    //         return (
    //           <Form className="form">
    //             <Loader hasOverlay visible={isLoading} />
    //             <div className="personal-data__row">
    //               <div className="personal-data__col">
    //                 <h2 className="personal-data__title">
    //                   conheça sua oferta de
    //                   <strong className="personal-data__title-strong">
    //                     empréstimo iti Itaú
    //                   </strong>
    //                 </h2>
    //                 <picture className="personal-data__image-wrapper">
    //                   <img
    //                     src={phonesImage}
    //                     alt="Celulares com o aplicativo do Iti aberto"
    //                   />
    //                 </picture>
    //               </div>
    //               <div className="personal-data__col">
    //                 <Input
    //                   name="cpf"
    //                   id="cpf"
    //                   placeholder="000.000.000-00"
    //                   label="cpf"
    //                   mask="cpf"
    //                   inputMode="numeric"
    //                   type="text"
    //                   autoComplete="on"
    //                   rules={{
    //                     isRequired: true,
    //                     isCpf: true
    //                   }}
    //                   onClick={() => {
    //                     if (!lead?.trackEvent.cpf) {
    //                       ga.trackClick('alterarCPF')
    //                       addLeadData({ trackEvent: { cpf: true } })
    //                     }
    //                   }}
    //                 />
    //                 <Input
    //                   name="name"
    //                   id="name"
    //                   data-cy="cy-name-input"
    //                   placeholder="Antonio Coutinho"
    //                   label="nome"
    //                   type="text"
    //                   autoComplete="off"
    //                   rules={{
    //                     isRequired: true,
    //                     containNameTitle: true,
    //                     isNameComplete: true,
    //                     hasOnlyLetters: true,
    //                     wordsMoreThan: 2,
    //                     wordsLengthBiggestThan: 1,
    //                     wordsLengthSmallerThan: 20,
    //                     fieldLengthLessThan: 200
    //                   }}
    //                   onClick={() => {
    //                     if (!lead?.trackEvent.name) {
    //                       ga.trackClick('NomeCompleto')
    //                       addLeadData({ trackEvent: { name: true } })
    //                     }
    //                   }}
    //                 />
    //                 <Input
    //                   name="email"
    //                   id="email"
    //                   data-cy="cy-email-input"
    //                   placeholder="acoutinho@gmail.com"
    //                   label="e-mail"
    //                   type="email"
    //                   inputMode="email"
    //                   autoComplete="on"
    //                   required={true}
    //                   rules={{
    //                     isRequired: true,
    //                     isEmail: true
    //                   }}
    //                   onClick={() => {
    //                     if (!lead?.trackEvent.email) {
    //                       ga.trackClick('Email')
    //                       addLeadData({ trackEvent: { email: true } })
    //                     }
    //                   }}
    //                 />
    //                 <Input
    //                   name="phone"
    //                   id="phone"
    //                   data-cy="cy-phone-input"
    //                   placeholder="(00) 00000-0000"
    //                   label="celular"
    //                   mask="phone"
    //                   inputMode="tel"
    //                   type="text"
    //                   autoComplete="on"
    //                   required={true}
    //                   rules={{
    //                     isRequired: true,
    //                     isPhone: true
    //                   }}
    //                   onClick={() => {
    //                     if (!lead?.trackEvent.phone) {
    //                       ga.trackClick('Telefone')
    //                       addLeadData({ trackEvent: { phone: true } })
    //                     }
    //                   }}
    //                 />
    //                 {hasRecaptcha && (
    //                   <ReCAPTCHA
    //                     ref={recaptchaRef}
    //                     size="invisible"
    //                     sitekey={RECAPTCHA_KEY}
    //                     onChange={async (response) => {
    //                       setFieldValue('recaptcha', response)

    //                       submitForm()
    //                     }}
    //                   />
    //                 )}
    //                 <p className="text -small -disclaimer">
    //                   Ao continuar, autorizo os gestores de banco de dados do
    //                   cadastro positivo a disponibilizarem ao Itaú Unibanco S.A
    //                   o meu histórico de crédito. Estou ciente dos{' '}
    //                   <a
    //                     href="https://iti.itau/content/iti/termo-de-uso.html"
    //                     className="personal-data__link"
    //                     target="_blank"
    //                     rel="noreferrer"
    //                   >
    //                     termos de autorização
    //                   </a>
    //                   . e que poderei efetuar o
    //                   <a
    //                     href="https://www.itau.com.br/cadastro-positivo/"
    //                     className="personal-data__link"
    //                     target="_blank"
    //                     rel="noreferrer"
    //                   >
    //                     {' '}
    //                     cancelamento{' '}
    //                   </a>
    //                   a qualquer momento.
    //                   <br />
    //                   <br />
    //                   Autorizo também a consulta ao
    //                   <a
    //                     href="https://www.itau.com.br/cadastro-positivo/"
    //                     className="personal-data__link"
    //                     target="_blank"
    //                     rel="noreferrer"
    //                   >
    //                     {' '}
    //                     sistema de informações de crédito{' '}
    //                   </a>
    //                   (SCR) do Banco Central, estou ciente que meus dados serão
    //                   registrados no SCR e de acordo com a
    //                   <a
    //                     href="https://www.itau.com.br/cadastro-positivo/"
    //                     className="personal-data__link"
    //                     target="_blank"
    //                     rel="noreferrer"
    //                   >
    //                     {' '}
    //                     política de privacidade
    //                   </a>
    //                   .
    //                 </p>

    //                 <Navigation
    //                   prevStep={getPath(home.path)}
    //                   disabled={isDisabled}
    //                   isFixed={isValid}
    //                   nextButtonClick={
    //                     hasRecaptcha ? dispatchRecaptcha : () => {}
    //                   }
    //                   prevButtonClick={() => {
    //                     ga.trackClick('Voltar')
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //           </Form>
    //         )
    //       }}
    //     </Formik>
    //   </div>
    // </Layout>
  )
}

export default PersonalData
